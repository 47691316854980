import React from "react";

const RightArrowIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36ZM22.1736 24.3814C21.7707 24.7771 21.1233 24.7713 20.7277 24.3684C20.332 23.9655 20.3378 23.3181 20.7407 22.9224L24.1623 19.562H8.25767C7.69296 19.562 7.23518 19.1042 7.23518 18.5395C7.23518 17.9748 7.69296 17.517 8.25767 17.517H24.1623L20.7407 14.1565C20.3378 13.7608 20.332 13.1134 20.7277 12.7105C21.1233 12.3076 21.7707 12.3018 22.1736 12.6975L27.379 17.81C27.5748 18.0022 27.6851 18.2651 27.6851 18.5395C27.6851 18.8138 27.5748 19.0767 27.379 19.269L22.1736 24.3814Z"
        fill="white"
      />
    </svg>
  );
};

export default RightArrowIcon;
