import { Box, FormControlLabel, FormLabel, Grid, Radio } from "@mui/material";
// @ts-ignore
import MailchimpSubscribe from "react-mailchimp-subscribe";

import { Field, Form, Formik } from "formik";
import { RadioGroup, TextField } from "formik-mui";
import { DarkButton } from "./DarkButton";
import React from "react";
import * as yup from "yup";

const url =
  "https://biancabustamante.us14.list-manage.com/subscribe/post?u=4dbe646e9c91e341f038f71eb&amp;id=195fc11335";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  firstName: yup.string(),
  lastName: yup.string(),
  nftKnowledge: yup.string(),
});

type Props = {
  // isOpen: boolean;
  // onClose: any;
  onSuccess: any;
};

const MailChimpForm = ({ onSuccess }: Props) => {
  return (
    <Grid item>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }: any) => (
          <Formik
            initialValues={{
              email: "",
              firstName: "",
              lastName: "",
              nftKnowledge: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              subscribe({
                EMAIL: values.email,
                FNAME: values.firstName,
                LNAME: values.lastName,
                MMERGE6: values.nftKnowledge,
              });
              onSuccess();
            }}
          >
            {({ submitForm, isSubmitting }) => (
              <Form>
                <Field
                  component={TextField}
                  name="email"
                  type="email"
                  label="Email"
                  variant="standard"
                  placeholder="you@email.com"
                  margin="dense"
                  fullWidth
                />

                <Field
                  component={TextField}
                  name="firstName"
                  type="text"
                  label="First name"
                  variant="standard"
                  placeholder="Chris"
                  margin="dense"
                  fullWidth
                />

                <Field
                  component={TextField}
                  name="lastName"
                  type="text"
                  label="Last name"
                  variant="standard"
                  placeholder="Lee"
                  margin="dense"
                  fullWidth
                />

                <Box py={2}>
                  <FormLabel sx={{ color: "#000" }}>
                    How much do you know about NFTs?
                  </FormLabel>
                  <Field component={RadioGroup} row name="nftKnowledge">
                    <FormControlLabel
                      value="Nothing."
                      control={<Radio disabled={isSubmitting} />}
                      label="Nothing"
                      disabled={isSubmitting}
                    />
                    <FormControlLabel
                      value="A little bit."
                      control={<Radio disabled={isSubmitting} />}
                      label="A bit"
                      disabled={isSubmitting}
                    />
                    <FormControlLabel
                      value="Heaps!"
                      control={<Radio disabled={isSubmitting} />}
                      label="Heaps"
                      disabled={isSubmitting}
                    />
                  </Field>
                </Box>

                <DarkButton
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                  fullWidth
                >
                  Submit
                </DarkButton>
              </Form>
            )}
          </Formik>
        )}
      />
    </Grid>
  );
};

export default MailChimpForm;
