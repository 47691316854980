import React, { useCallback, useMemo, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../styles/Whitelist.module.css";
import { IWalletState, useWallet } from "./UseWallet";
import useWhitelistCreate from "../api/useWhitelistCreate";
import { isNil } from "lodash";
import { Box, Card, Grid, IconButton, Modal, Typography } from "@mui/material";
import { WhitelistStatus } from "../models/Whitelist";
import { useQuery } from "react-query";
import { ErrorResponse } from "../api/types";
import MessageAlert from "./MessageAlert";
// @ts-ignore
import { getBackendErrorMessage } from "../lib/backendError";
import { CtaButton } from "./CtaButton";
import { SignUpModal } from "./SignUpModal";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import { ButtonIcon } from "./ButtonIcon";
import Title from "./Title";
import { event } from "../lib/gtm";
import DownArrow from "./DownArrow";
import RightArrowIcon from "./RightArrowIcon";

type Props = {};

export interface IAlertState {
  isOpen: boolean;
  closeAlert: any;
  message: string;
  handleClose: any;
  vertical: "top" | "bottom";
  horizontal: "left" | "center" | "right";
  severity: any;
}

export const initialAlertState: IAlertState = {
  isOpen: false,
  closeAlert: null,
  message: "",
  handleClose: null,
  vertical: "top",
  horizontal: "center",
  severity: null,
};

const Whitelist = (props: Props) => {
  const { mutate: whitelistCreateMutate, isLoading: isWhitelistCreateLoading } =
    useWhitelistCreate();

  const [alertState, setAlertState] = useState<IAlertState>(initialAlertState);
  const [isWhitelistJoined, setIsWhitelistJoined] = useState<boolean>(false);
  const [isCreateWalletModalOpen, setIsCreateWalletModalOpen] =
    useState<boolean>(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState<boolean>(false);

  const { data: whitelistStatusData, isLoading: isWhitelistStatusLoading } =
    useQuery<WhitelistStatus, ErrorResponse>(`/whitelist/status/`, {
      onError: (error) => {
        const errorMessage = getBackendErrorMessage(error);
        setAlertState({
          isOpen: true,
          closeAlert,
          message: errorMessage,
          severity: "error",
          handleClose: null,
          horizontal: "center",
          vertical: "top",
        });
      },
    });

  const closeAlert = useCallback(() => {
    setAlertState({ ...alertState, isOpen: false });
  }, [setAlertState, alertState]);

  const onWalletConnect = useCallback(
    async ({ address }: IWalletState) => {
      if (isNil(address)) {
        return;
      }

      whitelistCreateMutate(
        { address },
        {
          onError: (error) => {
            const errorMessage = getBackendErrorMessage(error);
            setAlertState({
              isOpen: true,
              closeAlert,
              message: errorMessage,
              severity: "error",
              handleClose: null,
              horizontal: "center",
              vertical: "top",
            });
          },
          onSuccess: () => {
            setIsWhitelistJoined(true);
            setAlertState({
              isOpen: true,
              closeAlert,
              message: "You have joined the whitelist.",
              severity: "success",
              handleClose: null,
              horizontal: "center",
              vertical: "top",
            });
            setIsSignUpModalOpen(true);
          },
        }
      );
    },
    [whitelistCreateMutate, closeAlert]
  );

  const onWalletError = useCallback(
    (error) => {
      setAlertState({
        isOpen: true,
        closeAlert,
        message: `Unable to connect to wallet: ${error}`,
        severity: "error",
        handleClose: null,
        horizontal: "center",
        vertical: "top",
      });
    },
    [setAlertState, closeAlert]
  );

  const onNoWallet = useCallback(
    () => setIsCreateWalletModalOpen(true),
    [setIsCreateWalletModalOpen]
  );

  const handleModalClose = useCallback(() => {
    setIsCreateWalletModalOpen(false);
  }, [setIsCreateWalletModalOpen]);

  const handleSignUpModalClose = useCallback(() => {
    setIsSignUpModalOpen(false);
  }, [setIsSignUpModalOpen]);

  const onSignUpSuccess = useCallback(() => {
    setIsSignUpModalOpen(false);
    setAlertState({
      isOpen: true,
      closeAlert,
      message: "You have signed up for updates about the drop.",
      severity: "success",
      handleClose: null,
      horizontal: "center",
      vertical: "top",
    });
  }, [setIsSignUpModalOpen, closeAlert]);

  const handleNoWalletClick = (event: React.MouseEvent) => {
    document?.getElementById("how-to-section")?.scrollIntoView(true);
  };

  const { provider, web3Provider, address, chainId, handleConnectWallet } =
    useWallet(onWalletConnect, onWalletError, onNoWallet);

  const handleOgWhitelistClick = useCallback(() => {
    event({
      action: "click",
      category: "signup",
      label: "og_whitelist",
      value: "",
    });
    handleConnectWallet();
  }, [handleConnectWallet]);

  const whitelistOverallStatus = useMemo(() => {
    if (isNil(whitelistStatusData)) {
      return null;
    }

    if (whitelistStatusData.is_og_whitelist_open) {
      return "open";
    }

    return "closed";
  }, [whitelistStatusData]);

  const isOgWhitelistOpen = useMemo(
    () =>
      !isNil(whitelistStatusData) && whitelistStatusData.is_og_whitelist_open,
    [whitelistStatusData]
  );

  const spotsFilled = useMemo(() => {
    if (isNil(whitelistStatusData)) {
      return null;
    }

    if (isNil(whitelistStatusData.og_whitelist_count)) {
      return null;
    }

    if (!whitelistStatusData.is_og_whitelist_open) {
      return null;
    }

    return (
      <Box mt={1} sx={{ fontSize: 12, textAlign: "center" }}>
        <Typography sx={{ fontWeight: 800, fontSize: 12 }} component="span">
          {whitelistStatusData.og_whitelist_count}
        </Typography>{" "}
        / {whitelistStatusData.og_whitelist_size} spots filled
      </Box>
    );
  }, [whitelistStatusData]);

  const isOgWhitelistFull = useMemo(() => {
    if (isNil(whitelistStatusData)) {
      return false;
    }

    return !whitelistStatusData.is_og_whitelist_open;
  }, [whitelistStatusData]);

  const ogWhitelistButtonText = isOgWhitelistFull
    ? "Whitelist is Full"
    : "Join Whitelist";

  const isOgButtonDisabled =
    !isOgWhitelistOpen ||
    isWhitelistCreateLoading ||
    isWhitelistStatusLoading ||
    isWhitelistJoined;

  return (
    <div className={styles.container}>
      <Grid
        className={styles.containerImage}
        container
        spacing={1}
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: "6vh" }}
        px={5}
        key={"whitelist-section"}
      >
        <Grid
          container
          className={styles.titles}
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid item>
            <Title
              text={`WHITELIST ${
                whitelistOverallStatus ? whitelistOverallStatus : "OPEN"
              }`}
            />
          </Grid>
          <Grid item>
            <Typography variant="h2" className={styles.subtext}>
              Be first in line to mint a Bianca NFT at an exclusive price.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          key={"whitelist-container"}
          sx={{ flexDirection: { xs: "column-reverse", lg: "row" } }}
        >
          <Grid item>
            <Card className={styles.card}>
              <h2>Whitelist</h2>
              <Box className={styles.cardbox}>
                <List>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      <LocalFireDepartmentIcon sx={{ color: "#E85438" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography sx={{ fontSize: 12 }}>
                            Limited to <strong>2000</strong>
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      <LocalFireDepartmentIcon sx={{ color: "#E85438" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography sx={{ fontSize: 12 }}>
                            0.07 ETH
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      <LocalFireDepartmentIcon sx={{ color: "#E85438" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography sx={{ fontSize: 12 }}>
                            First in line to mint, with exclusive price.
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
              <CtaButton
                variant="contained"
                onClick={handleOgWhitelistClick}
                disabled={isOgButtonDisabled}
              >
                <Box>{ogWhitelistButtonText}</Box>
                <Box>
                  <ButtonIcon
                    disabled={isOgButtonDisabled}
                    icon={<RightArrowIcon />}
                  />
                </Box>
              </CtaButton>
              {spotsFilled}
            </Card>
          </Grid>

          <Grid item>
            <Card className={styles.card}>
              <h2>Public</h2>
              <Box className={styles.cardbox}>
                <List>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      <LocalFireDepartmentIcon sx={{ color: "#E85438" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography sx={{ fontSize: 12 }}>
                            Subject to availability
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      <LocalFireDepartmentIcon sx={{ color: "#E85438" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography sx={{ fontSize: 12 }}>
                            0.15 ETH
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      <LocalFireDepartmentIcon sx={{ color: "#E85438" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography sx={{ fontSize: 12 }}>
                            Up to 2x NFTs per wallet
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
            </Card>
          </Grid>
        </Grid>

        <div
          id={"no-wallet"}
          onClick={handleNoWalletClick}
          className={styles.nowallet}
        >
          <Grid
            container
            className={styles.nowallet}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <p>I don&apos;t have a wallet yet...</p>
            <Box className={styles.downArrow}>
              <DownArrow />
            </Box>
          </Grid>
        </div>
        <Modal
          open={isCreateWalletModalOpen}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={styles.modalBox}>
            <IconButton
              aria-label="close"
              onClick={handleModalClose}
              className={styles.modalCloseButton}
            >
              <CloseIcon />
            </IconButton>
            <Typography mb={2}>
              On your phone?{" "}
              <a href="https://metamask.app.link/dapp/nft.thedarkhorse.xyz/">
                Open MetaMask here
              </a>
            </Typography>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Getting a crypto wallet
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              MetaMask is a software cryptocurrency wallet used to interact with
              the Ethereum blockchain. It allows users to access their Ethereum
              wallet through a browser extension or mobile app.{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://metamask.io/download/"
              >
                Download it here.
              </a>
            </Typography>
          </Box>
        </Modal>
        <SignUpModal
          isOpen={isSignUpModalOpen}
          onClose={handleSignUpModalClose}
          onSuccess={onSignUpSuccess}
        />
      </Grid>
      <MessageAlert {...alertState} />
    </div>
  );
};

export default Whitelist;
