import Web3Modal from "web3modal";
import { useCallback, useEffect, useState } from "react";
import { providers } from "ethers";
import {
  CREATE_WALLET_PROVIDER,
  providerOptions,
} from "../constants/walletButtonConstants";
import { isNil } from "lodash";

export interface IWalletState {
  provider: any;
  web3Provider: any;
  address: string | null;
  chainId: number | null;
}

const initialWalletState: IWalletState = {
  provider: null,
  web3Provider: null,
  address: null,
  chainId: null,
};

export interface IWallet {
  provider: any;
  web3Provider: any;
  address: string | null;
  chainId: number | null;
  handleConnectWallet: any;
  handleDisconnectWallet: any;
}

// @ts-ignore
export const useWallet = (onConnect, onError, onNoWallet): IWallet => {
  const [walletState, setWalletState] =
    useState<IWalletState>(initialWalletState);
  const { provider, web3Provider, address, chainId } = walletState;

  const [web3Modal, setWeb3Modal] = useState<any>(null);

  // TODO check for mainnet/testnet chain IDs

  useEffect(() => {
    if (!isNil(web3Modal)) {
      return;
    }

    const modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: false,
      providerOptions,
    });
    setWeb3Modal(modal);
  }, [web3Modal]);

  const handleConnectWallet = useCallback(
    async (values) => {
      if (isNil(web3Modal)) {
        console.log("Web3 modal not present.");
        return;
      }

      let provider;
      try {
        provider = await web3Modal.connect();
      } catch (error: any) {
        console.log({ error });

        if (error === "Modal closed by user") {
          // Don't treat as an error.
          return;
        }

        if (onError) {
          onError(error.message);
        }
        return;
      }

      if (provider === CREATE_WALLET_PROVIDER) {
        await onNoWallet();
        return;
      }

      // We plug the initial `provider` into ethers.js and get back
      // a Web3Provider. This will add on methods from ethers.js and
      // event listeners such as `.on()` will be different.
      const web3Provider = new providers.Web3Provider(provider);

      const signer = web3Provider.getSigner();
      const address = await signer.getAddress();
      const network = await web3Provider.getNetwork();

      const updatedState = {
        provider,
        web3Provider,
        address,
        chainId: network.chainId,
      };
      setWalletState(updatedState);

      if (!isNil(onConnect)) {
        await onConnect(updatedState, values);
      }
    },
    [onConnect, setWalletState, web3Modal, onNoWallet, onError]
  );

  const handleDisconnectWallet = useCallback(async () => {
    if (isNil(web3Modal)) {
      return;
    }

    // @ts-ignore
    await web3Modal.clearCachedProvider();

    if (provider?.disconnect && typeof provider.disconnect === "function") {
      await provider.disconnect();
    }
    setWalletState(initialWalletState);
  }, [provider, setWalletState, web3Modal]);

  // A `provider` should come with EIP-1193 events. We'll listen for those events
  // here so that when a user switches accounts or networks, we can update the
  // local React state with that new information.
  useEffect(() => {
    if (provider?.on) {
      if (chainId) {
        // const chainData = getChainData(chainId);
        // console.log({chainData});
      }

      const handleAccountsChanged = (accounts: string[]) => {
        // eslint-disable-next-line no-console
        console.log("accountsChanged", accounts);
        setWalletState({ ...walletState, address: accounts[0] });
      };

      // https://docs.ethers.io/v5/concepts/best-practices/#best-practices--network-changes
      const handleChainChanged = (_hexChainId: string) => {
        window.location.reload();
      };

      const handleDisconnect = (error: { code: number; message: string }) => {
        // eslint-disable-next-line no-console
        console.log("disconnect", error);
        handleDisconnectWallet();
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      // Subscription Cleanup
      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [chainId, provider, handleDisconnectWallet, walletState]);

  return { ...walletState, handleConnectWallet, handleDisconnectWallet };
};
