import { useMutation } from "react-query";
import {ErrorResponse, ServerResponse} from "./types";
import Axios from "../lib/api";

type WhitelistCreateParams = {
    address: string;
};

export default function useWhitelistCreate() {
    return useMutation<ServerResponse, ErrorResponse, WhitelistCreateParams>(
        ({ address }: WhitelistCreateParams) =>
            Axios.post<ServerResponse, any>("/whitelist/create/", { input: { address } }),
    );
}
