import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import styles from "../styles/Howto.module.css";

type Props = {};

const Howto = (props: Props) => {
  return (
    <Grid
      container
      spacing={1}
      pb={6}
      direction="column"
      justifyContent="center"
      alignItems="center"
      key={"howto-section"}
      id={"how-to-section"}
      className={styles.container}
    >
      <div className={styles.titles}>
        <p className={styles.title}>How To:</p>
        <p className={styles.subtitle}>Create and connect wallet</p>
      </div>

      <Container maxWidth="md">
        <Grid
          container
          spacing={6}
          justifyContent="center"
          alignItems="center"
          key={"whitelist-container"}
          direction={{ xs: "column", md: "row" }}
          sx={{ marginBottom: "40px" }}
        >
          <Grid
            item
            className={styles.card}
            sx={{ width: 400, maxWidth: { md: 500 } }}
          >
            <img src="/images/whitelist/step1.png" alt="Step 1" />
            <h3>Download MetaMask</h3>
            <p>
              The Chrome MetaMask extension will allow you to make purchases
              with Ethereum. If you are on mobile, use the MetaMask app.
            </p>
          </Grid>
          <Grid
            item
            className={styles.card}
            sx={{ width: 400, maxWidth: { md: 500 } }}
          >
            <img src="/images/whitelist/step2.png" alt="Step 2" />
            <h3>Connect Wallet</h3>
            <p>
              Connect your MetaMask wallet to our website to join the whitelist.
            </p>
          </Grid>
          <Grid
            item
            className={styles.card}
            sx={{ width: 400, maxWidth: { md: 500 } }}
          >
            <img src="/images/whitelist/step3.png" alt="Step 3" />
            <h3>Add ETH</h3>
            <p>
              Make sure you have ETH in your wallet before the Mint date. Then
              you’re all set to mint your NFT on drop day.
            </p>
          </Grid>
        </Grid>

        <div className={styles.faqs}>
          <Accordion className={styles.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={styles.accordionsummary}
            >
              <Typography>How do you install MetaMask?</Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.accordiondetails}>
              <Typography>
                MetaMask is a software cryptocurrency wallet used to interact
                with the Ethereum blockchain. It allows users to access their
                Ethereum wallet through a browser extension or mobile app.{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://metamask.io/download/"
                >
                  Download it here.
                </a>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className={styles.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              className={styles.accordionsummary}
            >
              <Typography>How do I buy/transfer ETH?</Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.accordiondetails}>
              <Typography>
                You can purchase ETH through your MetaMask wallet using Wyre or
                send ETH easily from an exchange like Binance or Coinbase.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className={styles.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
              className={styles.accordionsummary}
            >
              <Typography>
                When can I buy The Dark Horse NFT Access Pass?
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.accordiondetails}>
              <Typography>
                From April 26 you can join the Whitelist which will give you the
                opportunity to be the first to mint The Dark Horse NFT Access
                Pass at an exclusive price.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className={styles.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4a-content"
              id="panel4a-header"
              className={styles.accordionsummary}
            >
              <Typography>
                Where can I find more about Bianca Bustamante?
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.accordiondetails}>
              <Typography>
                You can find information about Bianca on her{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.biancabustamante.com"
                >
                  website
                </a>{" "}
                and follow her on Twitter and Instagram @racerbia.
              </Typography>
              <Typography>
                Register your email to get updates and we will also post updates
                in{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://discord.gg/A7gVznZBJz"
                >
                  Discord
                </a>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </Container>
    </Grid>
  );
};

export default Howto;
