import CloseIcon from "@mui/icons-material/Close";

import { Snackbar, Alert, IconButton } from "@mui/material";

type Props = {
  isOpen: boolean;
  closeAlert: any;
  message: string;
  handleClose: any;
  vertical: "top" | "bottom";
  horizontal: "left" | "center" | "right";
  severity: any;
};

const MessageAlert = (props: Props) => {
  const {
    vertical,
    horizontal,
    severity,
    isOpen,
    message,
    handleClose,
    closeAlert,
  } = props;

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={isOpen}
      onClose={handleClose}
      key={`${vertical}.${horizontal}.${message}`}
    >
      <Alert
        severity={severity}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              closeAlert();
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default MessageAlert;
