import Head from "next/head";
import styles from "../styles/Home.module.css";
import Howto from "../components/Howto";
import Whitelist from "../components/Whitelist";

export const Home = (): JSX.Element => {
  return (
    <>
      <Head>
        <title>The Dark Horse - Whitelist</title>
        <meta
          property="og:title"
          content="The Dark Horse | Bianca Bustamante | Whitelist"
          key="title"
        />
        <meta
          name="description"
          content="Strap in and take a ride on The Dark Horse - a groundbreaking NFT Access Pass supporting the rapid rise of Filipino race driver, Bianca Bustamante. By joining the drop, instantly gain access to the BiaCrew community, exclusive benefits/rewards, rare BTS content, and a role in supporting women in motorsport."
        />
        <link rel="canonical" href="https://nft.thedarkhorse.xyz" />
        <meta
          property="og:description"
          content="Strap in and take a ride on The Dark Horse - a groundbreaking NFT Access Pass supporting the rapid rise of Filipino race driver, Bianca Bustamante. By joining the drop, instantly gain access to the BiaCrew community, exclusive benefits/rewards, rare BTS content, and a role in supporting women in motorsport."
        />
        <meta
          property="og:image"
          content="https://nft.thedarkhorse.xyz/images/ogimage.png"
        />
        <meta property="og:image:width" content="2500" />
        <meta property="og:image:height" content="1330" />
        <meta property="og:url" content="https://nft.thedarkhorse.xyz" />
        <meta property="og:site_name" content="The Dark Horse" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="The Dark Horse | Bianca Bustamante"
        />
        <meta
          name="twitter:description"
          content="Strap in and take a ride on The Dark Horse - a groundbreaking NFT Access Pass supporting the rapid rise of Filipino race driver, Bianca Bustamante. By joining the drop, instantly gain access to the BiaCrew community, exclusive benefits/rewards, rare BTS content, and a role in supporting women in motorsport."
        />
        <meta
          name="twitter:image"
          content="https://nft.thedarkhorse.xyz/images/ogimage.png"
        />
      </Head>
      <div className={styles.homeContainer}>
        <Whitelist />
        <Howto />
      </div>
    </>
  );
};

export default Home;
