import * as React from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Button from "@mui/material/Button";
import get from "lodash/get";

interface Props {
  loading?: boolean;
  disabled?: boolean;
}

export const CtaButton = styled(Button)<Props>`
  background: ${(props) => {
    if (get(props, "disabled", false)) {
      return "#2D2B30";
    }

    if (get(props, "loading", false)) {
      return "#2D2B30";
    }

    return "linear-gradient(20deg, #73358F 0%, #BC3852 35%, #ED5736 75%, #E6824B 100%)";
  }};
  width: 100%;
  border-radius: 30px;
  padding: 10px;
  font-weight: 800;
  color: ${(props) => {
    if (get(props, "disabled", false)) {
      return "#ccc";
    }

    if (get(props, "loading", false)) {
      return "#ccc";
    }

    return "#fff";
  }};
  text-transform: none;
  border: 0;
  opacity: 1;
  &:disabled {
    background: #2d2b30;
    color: #ccc;
    box-shadow: none;
  }
  &:hover {
    box-shadow: 0 5px 15px rgba(145, 92, 182, 0.7);
  }
`;
