import { Box, CircularProgress } from "@mui/material";
import React from "react";
import get from "lodash/get";

interface Props {
  disabled?: boolean;
  loading?: boolean;
  icon: any;
}

export const ButtonIcon = (props: Props) => {
  let icon;

  if (get(props, "disabled", false)) {
    return null;
  }

  if (get(props, "loading", false)) {
    icon = <CircularProgress />;
  } else {
    icon = props.icon;
  }

  return (
    <Box sx={{ position: "absolute", right: "5px", top: "4px" }}>{icon}</Box>
  );
};
