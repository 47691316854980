import React from "react";
import * as yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, IconButton, Modal, Typography } from "@mui/material";
// @ts-ignore
import MailchimpSubscribe from "react-mailchimp-subscribe";
import styles from "../styles/SignUpModal.module.css";
import MailChimpForm from "./MailChimpForm";

const url =
  "https://biancabustamante.us14.list-manage.com/subscribe/post?u=4dbe646e9c91e341f038f71eb&amp;id=195fc11335";

type Props = {
  isOpen: boolean;
  onClose: any;
  onSuccess: any;
};

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  firstName: yup.string(),
  lastName: yup.string(),
  nftKnowledge: yup.string(),
});

export const SignUpModal = ({ isOpen, onClose, onSuccess }: Props) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.modalBox}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={styles.modalCloseButton}
        >
          <CloseIcon />
        </IconButton>
        <Grid container className={styles.contentContainer}>
          <Grid item>
            <Typography
              variant="h3"
              mb={1}
              sx={{ fontWeight: 700, fontSize: 20, textAlign: "center" }}
            >
              Notify me on drop day
            </Typography>
            <Box sx={{ fontSize: 14, textAlign: "center" }} mb={2}>
              We&apos;ll email you as soon as the minting window opens.
            </Box>
          </Grid>
          <MailChimpForm onSuccess={onSuccess} />
        </Grid>
      </Box>
    </Modal>
  );
};
