import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

export const DarkButton = styled(Button)({
  background: "#000",
  color: "#fff",
  borderRadius: 30,
  width: "100%",
  padding: 10,
  fontWeight: 800,
  textTransform: "none",
  border: 0,
  "&:disabled": {
    backgroundColor: "#2D2B30",
    borderColor: "#2D2B30",
    color: "#ccc",
  },
  "&:hover": {
    backgroundColor: "#222",
  },
});
