import WalletConnectProvider from "@walletconnect/web3-provider";
import { AccountBalanceWallet } from "@mui/icons-material";
// import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import WalletLink from "walletlink";

export const CREATE_WALLET_PROVIDER = "CREATE_WALLET";

// https://docs.walletconnect.com/quick-start/dapps/web3-provider#setup
const INFURA_ID = "27e484dcd9e3efcfd25a83a78777cdf1";

export const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: INFURA_ID, // required
    },
    display: {
      description: "For phone users and other wallets.",
    },
  },
  "custom-wallet": {
    // must start with 'custom'
    display: {
      logo: "/images/wallet.png",
      name: "Don't have a wallet?",
      description: "Create a wallet now.",
    },
    options: {
      appName: "Createwallet",
      networkUrl: ``,
      chainId: 1,
    },
    package: WalletLink,
    connector: async (_: any, options: any) => {
      return CREATE_WALLET_PROVIDER;
    },
  },
};
