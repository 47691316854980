import { Box } from "@mui/material";
import React from "react";

const DownArrow = () => {
  return (
    <Box sx={{ cursor: "pointer" }}>
      <img src="/images/whitelist/down-arrow.svg" alt="Arrow to next section" />
    </Box>
  );
};

export default DownArrow;
