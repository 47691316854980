import get from "lodash/get";
import isNil from "lodash/isNil";

const GENERIC_ERROR = "Something went wrong. Please try again later.";

export const getBackendErrorMessage = (error) => {
  if (isNil(error)) {
    return GENERIC_ERROR;
  }

  const backendError = get(error, "response.data.errors.0.message");

  if (!isNil(backendError)) {
    return backendError;
  }

  const walletError = get(error, "data.message");

  if (!isNil(walletError)) {
    return walletError;
  }

  return get(error, "message", GENERIC_ERROR);
};
