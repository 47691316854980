import { Typography } from "@mui/material";
import styles from "../styles/Title.module.css";
import React from "react";

interface Props {
  text: string;
}

const Title = ({ text }: Props) => {
  return (
    <Typography variant="h1" gutterBottom className={styles.title}>
      {text}
    </Typography>
  );
};

export default Title;
